.project-row {
    justify-content: space-between;
    max-width: 1185px;
    margin: 0 auto;
}

.project-card {
    height: 100%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.project-card-img {
    object-fit: cover;
    height: auto;
    max-height: 400px;
    object-position: center;
}

.project-card .card-title {
    font-size: 1.35rem; 
    font-weight: 600;
}

.project-card .card-text {
    font-size: 1.2rem; 
    line-height: 1.6;   
}

.update-date {
    font-size: 0.9rem;
    font-weight: 500;
    color: #555;
    margin-bottom: 0.25rem;
}

.scroll-hint {
    text-align: center;
    font-style: italic;
    color: #777;
    margin-bottom: 12px;
    animation: bounce 2s infinite;
}
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(5px); }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .project-row {
        justify-content: space-around;
        max-width: 95vw;
    }
}

@media (max-width: 992px) {
    .project-row {
        padding: 0 5vw;
    }
}
